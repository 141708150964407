import { lazy } from "react";

export const Accordion = lazy(() => import("./Accordion"));
export const ArticleFeed = lazy(() => import("./ArticleFeed"));
export const Benefits = lazy(() => import("./Benefits"));
export const BlockQuote = lazy(() => import("./BlockQuote"));
export const BranchLocations = lazy(() => import("./BranchLocations"));
export const BranchLookupPanel = lazy(() => import("./BranchLookupPanel"));
export const BrandPositioning = lazy(() => import("./BrandPositioning"));
export const BreadcrumbNavigation = lazy(() => import("./BreadcrumbNavigation"));
export const BottomCTA = lazy(() => import("./BottomCTA"));
export const CaseStudies = lazy(() => import("./CaseStudies"));
export const CollapsibleContent = lazy(() => import("./CollapsibleContent"));
export const CollapsibleSection = lazy(() => import("./CollapsibleSection"));
export const ContentWithSidebar = lazy(() => import("./ContentWithSidebar"));
export const Divider = lazy(() => import("./Divider"));
export const Features = lazy(() => import("./Features"));
export const FeaturedEmployers = lazy(() => import("./FeaturedEmployers"));
export const FeatureTabsList = lazy(() => import("./FeatureTabsList"));
export const Form = lazy(() => import("./Form"));
export const FormEmbed = lazy(() => import("./FormEmbed"));
export const GridButtonsGrid = lazy(() => import("./GridButtonsGrid"));
export const Header = lazy(() => import("./Header"));
export const Hero = lazy(() => import("./Hero"));
export const HeroCarousel = lazy(() => import("./HeroCarousel"));
export const HorizontalListings = lazy(() => import("./HorizontalListings"));
export const JobsFeed = lazy(() => import("./JobsFeed"));
export const KeyTabListing = lazy(() => import("./KeyTabListing"));
export const LatestBlogs = lazy(() => import("./LatestBlogs"));
export const LatestJobs = lazy(() => import("./LatestJobs"));
export const LatestJobsSidebar = lazy(() => import("./LatestJobsSidebar"));
export const LeadooWidget = lazy(() => import("./LeadooWidget"));
export const LogoCarousel = lazy(() => import("./LogoCarousel"));
export const MeetTheTeamPanel = lazy(() => import("./MeetTheTeamPanel"));
export const MiniCarousel = lazy(() => import("./MiniCarousel"));
export const MultiTiles = lazy(() => import("./MultiTiles"));
export const MultiCards = lazy(() => import("./MultiCards"));
export const NarrativePanel = lazy(() => import("./NarrativePanel"));
export const OfficeLocations = lazy(() => import("./BranchLocations"));
export const PromoButtons = lazy(() => import("./PromoButtons"));
export const PromoSection = lazy(() => import("./PromoSection"));
export const PromotionalCarousel = lazy(() => import("./PromotionalCarousel"));
export const PromotionalPanel = lazy(() => import("./PromotionalPanel"));
export const QuickJobSearch = lazy(() => import("./QuickJobSearch"));
export const Recruiter = lazy(() => import("./Recruiter"));
export const RecruiterListing = lazy(() => import("./RecruiterListing"));
export const RelatedJobs = lazy(() => import("./RelatedJobs"));
export const RichText = lazy(() => import("./RichText"));
export const SectionHeading = lazy(() => import("./SectionHeading"));
export const SimilarJobs = lazy(() => import("./SimilarJobs"));
export const SocialVideo = lazy(() => import("./SocialVideo"));
export const Spacer = lazy(() => import("./Spacer"));
export const StatCounterWidget = lazy(() => import("./StatCounterWidget"));
export const TeamBio = lazy(() => import("./TeamBio"));
export const TileGroups = lazy(() => import("./TileGroups"));
export const TrendingJobs = lazy(() => import("./TrendingJobs"));
